import React from "react"
import { Button, Box } from "grommet"
import { Link, Layout, SEO, toast } from "../../components"
import { useTheme } from "../../hooks"
import { reportClick, navigate } from "../../utils"

const IndexPage = () => {
  const theme = useTheme()
  const hanldeClick = () => {
    theme.setThemeMode(theme.themeMode === "dark" ? "light" : "dark")
  }
  const handleLogin = async () => {
    navigate("/boilerplates/dashboard")
  }

  const hanldeClickAlert = () => {
    toast("Hi")
    reportClick("test", "alert")
  }

  return (
    <Layout>
      <SEO title="Home" />
      <Box pad="small">
        <Link color="red" to="/boilerplates/nav" label="test">
          Go to boilerplate nav page
        </Link>
      </Box>
      <Box pad="small">
        <Button onClick={handleLogin} label="Login"></Button>
      </Box>
      <Box pad="small">
        <Button onClick={hanldeClick} primary label="toogle"></Button>
      </Box>
      <Box pad="small">
        <Button onClick={hanldeClickAlert} primary label="alert"></Button>
      </Box>
    </Layout>
  )
}

export default IndexPage
